import React, { Component } from 'react';
import './FormContact.scss';
import jGet, { jPost } from '../../../services/rest/connect.js';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

class FormContact extends Component {

  constructor() {
    super();
    this.state = {
      title: 'Contactanos',
      description: 'Muy pronto nos pondremos en contacto contigo.',
      open: false,
      message: '"I love snacks"',
      name: '',
      email: ''
    };
  }

  componentDidMount() {
    jGet({
      url: '/jsonapi/webform/webform/cbd7a5b5-d113-48ee-86dc-55a38a5d4d2a?fields[webform--webform]=title,description',
      withToken: true,
      then: (result) => {
        this.setState({ ...this.state,
          title: result.data.attributes.title,
          description: result.data.attributes.description
        });
      },
      err: (result) => {

      }
    });
  }

  sendMessage = (message) => {
    this.setState({ ...this.state, open: true, message: message });
  };

  handleClose = () => {
    this.setState({ ...this.state, open: false });
  };

  handleClick = (e) => {
    e.preventDefault();

    let name = this.state.name;
    let email = this.state.email;
    var data = JSON.stringify({
      webform_id: 'suscribirse',
      name: name,
      email: email
    });

    jPost({
      url: '/webform_rest/submit?_format=json',
      data: data,
      then: (result) => {
        if (result.error) {
          if (result.error.name) {
            this.sendMessage('Error nombre: ' + result.error.name);
          }
          if (result.error.email) {
            this.sendMessage('Error correo: ' + result.error.email);
          }
        }
        else {
          this.sendMessage('Muchas Gracias, fue enviado correctamente.');
          
          this.setState({...this.state, name: ''});
          this.setState({...this.state, email: ''});
        }
      },
      err: (result) => {
        this.sendMessage('Lo sentimos, pero no podemos completar el proceso de suscripción ahora.');
      }
    });
  }

  render () {
    var title = this.state.title;
    var description = this.state.description;
    var open = this.state.open;
    var message = this.state.message;
    var handleClick = this.handleClick;
    var sendMessage = this.sendMessage;
    var handleClose = this.handleClose;
    

    return (
      <div className="block form-contact" id="registrate" >
        <div>
          <h2 className="p line-bottom">{title}</h2>
          <span className="subtitle paragraph p" dangerouslySetInnerHTML={{__html: description }}></span>
          <form className="col s12">
            <Grid container spacing={3} >
              <Grid item sm={12} xs={12}>
                <TextField className="grid-input" id="name" label="Nombre"
                  onChange={(name) => { return this.setState({...this.state, name: name.target.value}) } }
                  value={this.state.name}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField className="grid-input" id="email" label="Correo"
                  onChange={(email) => this.setState({...this.state, email: email.target.value})}
                  value={this.state.email}
                />
              </Grid>
            </Grid>
            <div className="button-area">
              <Button variant="contained" color="primary" onClick={handleClick} >
                Enviar
              </Button>
            </div>
          </form>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            onClose={handleClose}
            message={message}
            key={'bottom' + 'center'}
          />
        </div>
      </div>
    );
  }
}

export default FormContact;