import React from "react"

import Layout from "../global/components/layout";
import { Component } from "react";
import FormContact from "../global/components/blocks/form-contact/FormContact";
import SEO from "../global/components/seo";
import './index.scss';

class IndexPage extends Component {

  constructor() {
    super();
    
    this.state = {
      load: false,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        load: true,
      });
    }, 0);
  }

  render() {
    return <Layout>
        <SEO title="Bienvenido" />
        <div className="section profesional" >
          <div className="section principal-sidebar" ></div>
          <div className="section principal-content" ></div>
        </div>
        <FormContact></FormContact>
      </Layout>
  }
}


export default IndexPage
